import { useStaticQuery, graphql } from "gatsby"

export const useVideoBlogQuery = () => {
  const {
    videoArticles: { articles },
    videoArticleTypes: { articleTypes },
  } = useStaticQuery(query)

  return articleTypes.map((type) => {
    return {
      articleType: {
        handle: type.handle,
      },
      articles: articles.filter(
        (article) => article.type.handle === type.handle
      ),
      linkText: "See more videos",
      title: type.title,
    }
  })
}

const query = graphql`
  query VideoBlogQuery {
    videoArticles: allStrapiArticles(
      filter: { type: { videoArticles: { eq: true } } }
    ) {
      articles: nodes {
        handle
        title
        description
        image {
          url
        }
        type {
          handle
          videoArticles
        }
      }
    }
    videoArticleTypes: allStrapiArticleTypes(
      filter: { videoArticles: { eq: true } }
    ) {
      articleTypes: nodes {
        title
        handle
      }
    }
  }
`
