import * as React from "react"
import { Layout } from "components/layouts/Layout/layout"
import BlogRow from "components/layouts/BlogRow"
import { Seo } from "components/seo"
import { useVideoBlogQuery } from "hooks/useVideoBlogQuery"
import Container from "components/elements/Container"

export default function BlogVideo() {
  const videoGroups = useVideoBlogQuery()

  // console.log(videoGroups)

  return (
    <Layout>
      <Seo title="Barista Blog Videos" />
      <Container>
        <div className="my-6 lg:my-16 flex flex-row justify-center">
          <div className={"w-full max-w[76rem] mx-auto"}>
            <h1 className="text-center text-idc-title font-extrabold text-5xl">
              {"Videos"}
            </h1>
            <div>
              {videoGroups.map((item: any) => (
                <BlogRow
                  key={item.title}
                  title={item.title}
                  linkText={item.linkText}
                  posts={item.articles}
                  handle={item.articleType.handle}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
